import { useState, useEffect } from 'react';
import loginImage from 'src/assets/images/slider/slide-1.webp';
import img2 from 'src/assets/images/slider/slide-2.webp';
import img3 from 'src/assets/images/slider/slide-3.webp';
import img4 from 'src/assets/images/slider/slide-4.webp';
import './imageSection.css';

const images = [loginImage, img2, img3, img4];

const ImageSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearTimeout(timer);
  }, [activeIndex]);

  return (
    <div id="images" className="img-section">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slider${index + 1}`}
          className={`image ${index === activeIndex ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};

export default ImageSection;
