import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowOutward } from "react-icons/md";
import { FiArrowUpRight } from "react-icons/fi";
import { GoAlertFill } from "react-icons/go";
import { MdDateRange } from "react-icons/md";
import { BiSolidInstitution } from "react-icons/bi";
import { IoBookSharp } from "react-icons/io5";
import { PiStudentFill } from "react-icons/pi";
import { FaChalkboardTeacher } from "react-icons/fa";
import Lottie from "lottie-react";
import animationData from "../../../assets/animations/math_teacher_animation.json";
import animationData2 from "../../../assets/animations/planet_animation.json";

import './SurveyBox.css';

export const BoxContent = ({
  icon,
  title,
  subtitle,
  notification,
  animationData,
  animationHeight,
  animationWidth,
  linkText = "Abrir encuesta",
  color = "primary",
  ...props
}) => {
  const [isHover, setIsHover] = useState(false);
  const lottieRef = useRef(null);

  const colorMap = {
    red: 'var(--color-secondary)',
    purple: 'var(--color-primary)',
    green: 'var(--color-green)',
  };

  const iconMap = {
    warning: <GoAlertFill />,
    calendar: <MdDateRange />,
    institution: <BiSolidInstitution />,
    books: <IoBookSharp />,
    student: <PiStudentFill />,
    teacher: <FaChalkboardTeacher />
  };

  const changeIcon = iconMap[icon] || iconMap.warning;
  const changeColor = colorMap[color] || colorMap.primary;

  const handleMouseOver = () => {
    setIsHover(true);
    if (lottieRef.current) {
      lottieRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    if (lottieRef.current) {
      lottieRef.current.goToAndStop(0, true);
    }
  };

  return (
    <main
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      style={{
        borderColor: isHover && changeColor,
        boxShadow: isHover && 'none',
      }}
      className="box animationScale"
      {...props}
    >
      {notification && <span className="header__notification-circle" style={{backgroundColor: changeColor}}></span>}
      <header className="box__header">
        <div 
          className='box-icon'
          style={{ backgroundColor: `${changeColor}`, color: 'white' }}>
          {changeIcon}
        </div>
        <div className="header__text">
          <p> {title} </p>
          <small
            style={{
              color: isHover && changeColor,
              boxShadow: isHover && 'none',
            }}
          >
            {subtitle}
          </small>
        </div>
      </header>
      {animationData && (
        <div className='lottie-wrapper'>
          <div className='lottie'>
            <Lottie
              lottieRef={lottieRef}
              animationData={animationData}
              loop={true}
              autoplay={false}
              style={{ 
                width: animationWidth,
                height: animationHeight,
                margin: "0 auto",
              }}
            />
          </div>
        </div>
      )}
      <button type="button" className="box__link-to">
        <p
          style={{
            color: isHover && changeColor,
            boxShadow: isHover && 'none',
          }}
          className="link-to__open-survey"
        >
          {linkText}
        </p>
        <div
          className='box-link-arrow'
          style={{
            color: isHover && changeColor,
            fontSize: '24px'
          }}
        >
          <MdArrowOutward/>
        </div>
      </button>
    </main>
  );
};



const SurveyBox = () => {
  const navigate = useNavigate();

  return (
    <section className="content">
      <div className='admin-menu-surveys-label'>Encuestas</div>
      <div className='box-wrapper'>
        <BoxContent
          notification={true}
          icon='teacher'
          title="Encuesta al docente"
          animationData={animationData}
          animationHeight={220}
          animationWidth={220}
          linkText="Abrir encuesta docente"
          subtitle={'Seleccionar curso para cargar encuestas'}
          color="red"
          onClick={() => navigate('/admin/teacher-survey')}
        />
      </div>
      <div className='box-wrapper'>
        <BoxContent
          notification={true}
          icon='student'
          title="Encuesta al estudiante"
          animationData={animationData2}
          animationHeight={200}
          animationWidth={200}
          linkText="Abrir encuesta estudiante"
          subtitle={'Seleccionar curso para cargar encuestas'}
          color='red'
          onClick={() => navigate('/admin/student-survey')}
        />
      </div>
    </section>
  );
};


export default SurveyBox;
