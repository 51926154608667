import React from 'react';
import { useNavigate } from 'react-router-dom';
import animationData from 'src/assets/animations/create_animation.json';
import animationData2 from 'src/assets/animations/books_animation.json';
import { BoxContent } from '../SurveyBox/SureyBox';
import './Units.css';


const Units = () => {
  const navigate = useNavigate();

  return (
    <div className='units-container'>
      <div className='admin-menu-class-label'>Clases</div>
      <div className='box-wrapper'>
        <BoxContent
          notification={true}
          icon='institution'
          title="Crear Instituci&oacute;n"
          animationData={animationData}
          animationHeight={160}
          animationWidth={160}
          linkText="Abrir encuesta docente"
          color="green"
          subtitle={'Acceder a la creación de colegio, escuela o liceo.'}
          onClick={() => navigate('/admin/establishments')}
        />
      </div>
      <div className='box-wrapper'>
        <BoxContent
          notification={true}
          icon='books'
          title="Unidades"
          animationData={animationData2}
          animationHeight={200}
          animationWidth={200}
          linkText="Abrir unidades"
          color="green"
          subtitle={'Seleccionar curso para cargar unidades'}
          onClick={() => navigate('/admin/units')}
        />
      </div>
    </div>
  );
};

export default Units;
