import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validateRecoveryToken, updatePassword } from 'src/services/admin/user.request';
import logo from 'src/assets/images/logo-civiconecta.webp';
import '../RecoverPassword/RecoverPassword.css';
import ImageSection from 'src/components/PublicSection/Login/ImageSection/ImageSection';
import CreatePasswordForm from './CreatePasswordForm';

const CreatePassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const styleButton = {
    width: '100%',
    fontSize: '15px',
    color: 'white',
    padding: '20px 20px',
    backgroundColor: 'var(--color-primary)',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  };

  const greyButton = {
    ...styleButton,
    backgroundColor: 'var(--gray-dark)',
    cursor: 'not-allowed',
  };

  const validateToken = async () => {
    try {
      const response = await validateRecoveryToken(token);
      if (response?.ok) {
        setIsTokenValid(true);
        setUserEmail(response.email);
      } else {
        setIsTokenValid(false);
        setValidationErrorMessage(response?.message || 'El enlace es inválido.');
      }
    } catch (error) {
      setIsTokenValid(false);
      setValidationErrorMessage('Error validando el token.');
    }
  };

  useEffect(() => {
    validateToken();
  }, [token]);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    checkPasswordsMatch(value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    checkPasswordsMatch(password, value);
  };

  const checkPasswordsMatch = (pwd, confirmPwd) => {
    setPasswordsMatch(pwd === confirmPwd);
  };

  const validatePassword = (pwd) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/;
    return regex.test(pwd);
  };

  const isButtonDisabled = 
    password.length === 0 ||
    confirmPassword.length === 0 ||
    !validatePassword(password) ||
    !passwordsMatch ||
    isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!validatePassword(password)) {
      return;
    }

    if (!passwordsMatch) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await updatePassword(userEmail, password);
      if (response.success) {
        alert('¡Contraseña cambiada con éxito!');
        window.location.href = '/login';
      } else {
        setPasswordError(response.message || 'Error al actualizar la contraseña.');
      }
    } catch (error) {
      setPasswordError('Ocurrió un error al cambiar la contraseña.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="create-password-section">
      <ImageSection />
      <div className="form-section">
        <div className="form-logo" style={{cursor: 'pointer', display: 'contents'}} onClick={() => window.location.href = 'https://civiconecta.cl'}>
          <img style={{ width: '35%' }} src={logo} alt="logo" />
        </div>

        {!isTokenValid ? (
          <div className="error-message">
            {validationErrorMessage}
          </div>
        ) : (
          <>
            <div className="instructions">
              <p style={{fontSize: '25px', color: 'var(--color-primary)', fontFamily: 'Nunito', textAlign: 'center'}}>Cambia tu contraseña</p>
              <p style={{fontSize: '15px', color: 'gray'}}>
                Ingresa una nueva contraseña considerando que debe tener entre 8 y 15 caracteres.
                Usa al menos una letra minúscula, una mayúscula, un número y un símbolo (punto, coma, paréntesis, entre otros).
              </p>
            </div>
            <CreatePasswordForm
              password={password}
              confirmPassword={confirmPassword}
              showPassword={showPassword}
              showConfirmPassword={showConfirmPassword}
              handlePasswordChange={handlePasswordChange}
              handleConfirmPasswordChange={handleConfirmPasswordChange}
              handleSubmit={handleSubmit}
              toggleShowPassword={() => setShowPassword(!showPassword)}
              toggleShowConfirmPassword={() => setShowConfirmPassword(!showConfirmPassword)}
              isButtonDisabled={isButtonDisabled}
              isLoading={isLoading}
              passwordError={passwordError}
              passwordsMatch={passwordsMatch}
              formSubmitted={formSubmitted}
              styleButton={styleButton}
              greyButton={greyButton}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default CreatePassword;
