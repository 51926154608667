import React from 'react';
import { useNavigate } from 'react-router-dom';
import animationData from 'src/assets/animations/calendar_animation.json';
import animationData2 from 'src/assets/animations/smooth_bell_animation.json';
import { BoxContent } from '../SurveyBox/SureyBox';
import './AsideBoxs.css';

const AsideBoxs = () => {
  const navigate = useNavigate();

  return (
    <aside className="container-boxs">
      <div className='admin-menu-events-label'>Eventos</div>
      <div className='box-wrapper'>
        <BoxContent
          notification={true}
          title="Situaciones emergentes"
          animationData={animationData2}
          animationHeight={250}
          animationWidth={250}
          onClick={() => navigate('/admin/situations')}
          linkText="Abrir situaciones"
          subtitle='Seleccionar nivel para cargar situaciones'
          color="purple"
          icon='warning'
        />
      </div>
      <div className='box-wrapper'>
        <BoxContent
          notification={true}
          title="Efemérides"
          animationData={animationData}
          animationHeight={170}
          animationWidth={170}
          onClick={() => navigate('/admin/ephemeris')}
          linkText="Abrir efemérides"
          subtitle={'Seleccionar nivel para cargar efemérides'}
          color="purple"
          icon='calendar'
        />
      </div>
    </aside>
  );
};

export default AsideBoxs;
