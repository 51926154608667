import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../RecoverPassword/RecoverPassword.css';

const CreatePasswordForm = ({
  password,
  confirmPassword,
  handlePasswordChange,
  handleConfirmPasswordChange,
  handleSubmit,
  isButtonDisabled,
  isLoading,
  passwordError,
  passwordsMatch,
  formSubmitted,
  styleButton,
  greyButton,
}) => {

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(prevState => !prevState);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(prevState => !prevState);

  return (
    <form onSubmit={handleSubmit} style={{ width: '80%' }}>
      <div className="form-group" style={{ width: '100%', position: 'relative' }}>
        <label className='form-label' htmlFor="password" style={{ color: 'var(--color-primary)'}}>Nueva Contraseña</label>
        <input
          style={{ width: '100%' }}
          type={showPassword ? 'text' : 'password'}
          id="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Ingresa nueva contraseña"
          className="form-input"
        />
        <button
          type="button"
          onClick={toggleShowPassword}
          className="toggle-password-visibility"
          style={{
            position: 'absolute',
            right: '10px',
            top: '73%',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            zIndex: 10000,
            color: 'var(--color-primary)'
          }}
        >
          {showPassword ? 
            <FaEyeSlash /> : <FaEye />}
        </button>
      </div>

      <div className="form-group" style={{ position: 'relative' }}>
        <label className='form-label' htmlFor="confirmPassword" style={{color: 'var(--color-primary)'}}>Confirmar Contraseña</label>
        <input
          type={showConfirmPassword ? 'text' : 'password'}
          id="confirmPassword"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          placeholder="Confirma tu contraseña"
          className="form-input "
        />
        <button
          type="button"
          onClick={toggleShowConfirmPassword}
          className="toggle-password-visibility"
          style={{
            position: 'absolute',
            right: '10px',
            top: '73%',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            zIndex: 10000,
            color: 'var(--color-primary)',
          }}
        >
          {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
        </button>
      </div>

      {formSubmitted && passwordError && (
        <p className="error-message">{passwordError}</p>
      )}

      {formSubmitted && !passwordsMatch && (
        <p className="error-message">Las contraseñas no coinciden.</p>
      )}
      <div style={{marginBottom: '30px'}}></div>

      <button
        className="button"
        type="submit"
        style={isButtonDisabled ? greyButton : styleButton}
        disabled={isButtonDisabled || isLoading}
      >
        {isLoading ? 'Cargando...' : 'Actualizar Contraseña'}
      </button>
    </form>
  );
};

export default CreatePasswordForm;
