import { useState, useEffect } from 'react';
import { getUserData } from 'src/utils/user';
import config from 'src/config';
import DisguisedInput from './DisguisedInput.js';
import ModalTrigger from './ModalTrigger';
import MenuDocenteIcon from 'src/assets/Icons/female-user-icon.svg';
import profile from 'src/assets/Icons/profile-image.svg';
import { IoMdSettings } from "react-icons/io";
import finishImage from 'src/assets/images/finish-survey.png';
import report from 'src/assets/images/report-container.png';
import './professor-profile.css';
import { useNavigate } from 'react-router-dom';
import FormModal from 'src/components/UI/FormModal/FormModal.jsx';
import CreatePasswordForm from 'src/components/Login/CreatePassword/CreatePasswordForm.js';
import { updatePassword } from 'src/services/admin/user.request';

const ProfessorInfo = ({ onClick }) => {
  const [userData, setUserData] = useState({});
  const [surveyData, setSurveyData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const currentUser = getUserData();
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const styleButton = {
    width: '100%',
    fontSize: '15px',
    color: 'white',
    padding: '20px 20px',
    backgroundColor: 'var(--color-primary)',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
  };

  const greyButton = {
    ...styleButton,
    backgroundColor: 'var(--gray-dark)',
    cursor: 'not-allowed',
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    checkPasswordsMatch(value, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    checkPasswordsMatch(password, value);
  };

  const checkPasswordsMatch = (pwd, confirmPwd) => {
    setPasswordsMatch(pwd === confirmPwd);
  };

  const validatePassword = (pwd) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,15}$/;
    return regex.test(pwd);
  };

  const isButtonDisabled = 
    password.length === 0 ||
    confirmPassword.length === 0 ||
    !validatePassword(password) ||
    !passwordsMatch ||
    isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!validatePassword(password)) {
      setPasswordError('La contraseña debe tener entre 8 y 15 caracteres, con al menos una letra mayúscula, una minúscula, un número y un símbolo.');
      return;
    }

    if (!passwordsMatch) {
      setPasswordError('Las contraseñas no coinciden.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await updatePassword(currentUser.email, password);
      if (response.success) {
        alert('¡Contraseña cambiada con éxito!');
        setShowModal(false);
      } else {
        setPasswordError(response.message || 'Error al actualizar la contraseña.');
      }
    } catch (error) {
      setPasswordError('Ocurrió un error al cambiar la contraseña.');
    } finally {
      setIsLoading(false);
    }
  };

  const getSurveyData = async () => {
    const baseURL = `${config.baseURL}/feedback/status/${currentUser.uuid}`;

    const response = await fetch(baseURL, {
      headers: {
        token: currentUser.token,
        "Content-Type": "application/json"
      },
      method: "GET"
    });
    const data = await response.json();
    setSurveyData(data.status);
  };

  useEffect(() => {
    getSurveyData();
  }, []);

  useEffect(() => {
    fetch(`${config.baseURL}/establishments/info/${currentUser.uuid}`, {
      headers: {
        token: currentUser.token
      }
    })
      .then(d => d.json())
      .then(data => setUserData(data.info));
  }, []);

  const data = [
    {
      label: "Email",
      value: currentUser.email
    }, {
      label: "Institución",
      value: userData.establishment
    }, {
      label: "Curso",
      value: userData.grade,
      letter: userData.letter
    }
  ];

  const inputs = data.map(input =>
    <DisguisedInput
      key={input.label}
      label={input.label}
      value={input.value}
      letter={input.letter}
    />
  );

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="professor-info-container">
      <div className='professor-info-title'>
        <img className='professor-profile-img' src={profile} alt="professor-profile-img" />
        <p>Perfil</p>
      </div>

      <div
        className='professor-info-title'
        style={{ cursor: 'pointer', width: '50%' }}
        onClick={() => setShowModal(true)}
      >
        <IoMdSettings
          className='professor-profile-img'
          color={'var(--color-secondary)'}
          style={{ height: '10%' }}
        />
        <p>Cambiar contraseña</p>
      </div>

      {showModal && (
        <FormModal showModal={showModal} closeModal={closeModal}>
          <h2 
            className='text-align'
            style={{color: 'var(--color-primary)'}}
          >
            Cambiar contraseña
          </h2>
          <p style={{color: 'grey'}}>
            Ingresa una nueva contraseña considerando que debe tener
            entre 8 y 15 caracteres. Usa al menos una letra minúscula, una
            mayúscula, un número y un símbolo (punto, coma, paréntesis,
            entre otros)
          </p>
          <CreatePasswordForm
            password={password}
            confirmPassword={confirmPassword}
            showPassword={showPassword}
            showConfirmPassword={showConfirmPassword}
            handlePasswordChange={handlePasswordChange}
            handleConfirmPasswordChange={handleConfirmPasswordChange}
            handleSubmit={handleSubmit}
            toggleShowPassword={() => setShowPassword(!showPassword)}
            toggleShowConfirmPassword={() => setShowConfirmPassword(!showConfirmPassword)}
            isButtonDisabled={isButtonDisabled}
            isLoading={isLoading}
            passwordError={passwordError}
            passwordsMatch={passwordsMatch}
            formSubmitted={formSubmitted}
            styleButton={styleButton}
            greyButton={greyButton}
          />
        </FormModal>
      )}

      <div className="professor-info-data">
        <div className="professor-info-data-cont">
          <div className='professor-info-data-img'>
            <img src={MenuDocenteIcon} alt="menu-icon" />
          </div>
          <div className='professor-info-data-name'>
            <p className="professor-info-name">{currentUser.name}</p>
            <p className="professor-info-type">Docente</p>
          </div>
        </div>
        <div className="disguised-inputs-cont">
          {inputs}
        </div>
      </div>

      {!surveyData?.survey?.completed || !surveyData?.teacher?.completed || !surveyData?.student?.completed ? (
        <ModalTrigger
          onClick={onClick}
          img={finishImage}
          text1={'¿Deseas terminar '}
          text2={'la encuesta?'}
          buttonText={'Finalizar encuesta'}
        />
      ) : (
        <ModalTrigger
          onClick={() => navigate('/public/results')}
          img={report}
          text1={'Informe de resultados'}
          text2={'¡Ya disponible!'}
          buttonText={'Ver reporte'}
          colorButton={'color-result'}
          colorIcon={'color-icon-button'}
        />
      )}
    </div>
  );
};

ProfessorInfo.displayName = 'ProfessorInfo';

export default ProfessorInfo;
