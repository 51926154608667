import React, { useState } from 'react';
import Button from 'src/components/UI/Button';
import back from 'src/assets/Icons/back-arrow.svg';
import { sendPasswordRecoveryLink } from 'src/services/admin/user.request';
import { useNavigate } from 'react-router-dom';
import './RecoverPassword.css';

const RecoverPasswordSection = ({emailSent, setEmailSent, emailInput, setEmailInput}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [serverMessage, setServerMessage] = useState('');

  const styleButton = {
    width: '100%',
    fontSize: '15px',
    font: 'inherit',
    color: 'white',
    padding: '20px 20px',
    backgroundColor: 'var(--color-primary)',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
  };

  const greyButton = {
    ...styleButton,
    backgroundColor: 'var(--gray-dark)',
    cursor: 'not-allowed',
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isButtonDisabled = emailInput.length === 0 || !isValidEmail(emailInput) || isLoading;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await sendPasswordRecoveryLink(emailInput);
      if (response.success) {
        setEmailSent(true);
      } else {
        console.error('Error:', response.message);
        setServerMessage('Usuario no encontrado');
      }
    } catch (error) {
      console.error('Unexpected error:', error);
      setServerMessage('Ha ocurrido un error');
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate()

  return (
    <div className='recover-section'>
      <form className='recover-form'>
        <div className="form-group">
          {
            !emailSent && (
              <>
                <label className="form-label" htmlFor="email" style={{ paddingLeft: 0}}>
                  Correo electrónico
                </label>
                <input
                  className="form-input input-name"
                  value={emailInput}
                  onChange={(e) => setEmailInput(e.target.value)}
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Escribe tu correo aquí"
                  spellCheck="false"
                />
              </>
            )
          }
        </div>
        <div className="form-group">
          {
            !emailSent ? 
              <Button
                onClick={handleSubmit}
                customStyles={isButtonDisabled ? greyButton : styleButton}
                disabled={isButtonDisabled}
                type="submit"
                text={isLoading ? "Cargando..." : "Enviar"}
              />
              :
              <Button
                onClick={() => navigate('/')}
                customStyles={isButtonDisabled ? greyButton : styleButton}
                /* disabled={isButtonDisabled} */
                /* type="submit" */
                text={"Volver al inicio"}
              />
          }
        </div>
        <div className='error-message'>
            {serverMessage}
          </div>
        <div className='recover-container'>
          <div className='return-recover' onClick={() => window.history.back()}>
            <img src={back} alt='back-icon' />Volver
          </div>
        </div>
      </form>
    </div>
  );
};

export default RecoverPasswordSection;
