import config from 'src/config';
import http from '../helpers/http.helper';

const BASE_URL = config.baseURL;

export const signIn = async (email, password) => {
  return fetch(`${BASE_URL}/auth/signIn`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password })
  }).then(response => response.json());
};

export const sendPasswordRecoveryLink = async (email) => {
  try {
    const response = await fetch(`${BASE_URL}/auth/resetPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, message: data.message };
    } else {
      return { success: false, message: data.message };
    }
  } catch (error) {
    console.error('Error in sending password recovery link:', error);
    return { success: false, message: 'Error' };
  }
};

export const validateRecoveryToken = async (Rtoken) => {
  try {
    const a = await http.getWithoutAuth(`${BASE_URL}/auth/validateRecovery/${Rtoken}`);
    return a
  } catch (error) {
    console.error(error);
  }
};

export const updatePassword = async (email, password) => {
  try {
    const data = await http.postWithoutAuth(`${BASE_URL}/auth/updatePassword`, { email, password });

    if (data.ok) {
      return { success: true, message: data.message };
    } else {
      return { success: false, message: data.message };
    }
  } catch (error) {
    console.error('Error in updating password:', error);
    return { success: false, message: 'Error' };
  }
};

export const signUpUserRole = payload => {
  return http.post(`${BASE_URL}/signUpUserRole`, payload);
};

export const updateActiveUser = (email, active) => {
  return http.put(`${BASE_URL}/updateActiveUser?email=${email}`, { active });
};

export const generateRandomPassword = () => {
  return http.get(`${BASE_URL}/generateRandomPassword`);
};


export const getDataTeachers = (establishmentId, courseId) => {
  // @ TODO: Remember to add the course id when the backend adds the change
  return http.get(`${BASE_URL}/establishments/${establishmentId}/course/1/teachers`);
};
