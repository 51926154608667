import React, { useState } from 'react';
import RecoverPasswordSection from './RecoverPasswordSection';
import RecoverPassMobile from './RecoverPassMobile';
import logo from 'src/assets/images/logo-civiconecta.webp';

import './RecoverPassword.css';
import ImageSection from 'src/components/PublicSection/Login/ImageSection/ImageSection';

const RecoverPassword = () => {

  const [emailSent, setEmailSent] = useState(false);
  const [emailInput, setEmailInput] = useState('');

  return (
    <div>
      <div className='recover-mobile-section'>
        <RecoverPassMobile />
      </div>
      <div className='recover-desk-section'>
        <ImageSection />
        <div className='recover-form-section'>
          <div className='recover-logo-section'>
            <a className='recover-form-logo' href="https://civiconecta.cl">
              <img style={{ width: '35%' }} src={logo} alt='logo' />
            </a>
          </div>
          <div className='recover-text-section'>
            <p>{emailSent ? 'Verifica tu correo electrónico' : '¿Contraseña olvidada?'}</p>
            <p>
              {
                emailSent ? (
                  <>
                    Como medida de seguridad, enviamos un enlace de recuperación a la cuenta{' '}
                    <span style={{ color: 'var(--color-primary)', fontWeight: 'bold' }}>
                      {emailInput}
                    </span>{' '}
                    para que puedas crear una nueva contraseña.
                  </>
                ) : (
                  `Para ayudarte ingresa la cuenta registrada en nuestra plataforma.
                  Esta corresponde al correo de uso institucional que tu empleador(a) informó.`
                )
              }
            </p>

          </div>
          <div style={{ width: '70%' }}>
            <RecoverPasswordSection
              emailSent={emailSent}
              setEmailSent={setEmailSent}
              emailInput={emailInput}
              setEmailInput={setEmailInput}
            />
          </div>
        </div>
        <div className='rights-reserved'>
          <p>® 2023, CiviConecta SpA. Todos los derechos reservados.</p>
        </div>
      </div>

    </div>
  );
};

export default RecoverPassword;