import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import './formModal.css';

const Modal = ({ showModal, closeModal, children }) => {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [closeModal]);

  if (!showModal) return null;

  const handleContentClick = (e) => e.stopPropagation();

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="form-modal-content" onClick={handleContentClick}>
        <button className="modal-close" onClick={closeModal}>
          <FaTimes size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
