import './TeacherList.css';

const Head = () => {
  return (
    <thead>
      <tr>
        <th>Establecimiento</th>
        <th>Nombre</th>
        <th>Email</th>
        <th>Contraseña</th>
        <th>Curso</th>
      </tr>
    </thead>
  );
};

export default Head;
